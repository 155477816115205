<template>
  <v-row>
    <v-col cols="12">
      <v-layout justify-center>
        <v-img :src="getImgUrl()"
               max-height="170"
               max-width="70%"
        contain></v-img>
      </v-layout>
    </v-col>
    <v-col cols="12" v-if="Object.keys(restaurantAddress).length > 0">
    <p class="text-center pt-3 pb-12  font-weight-medium">        {{ restaurantAddress.name || '' }}<br>
        {{ restaurantAddress.companyName || '' }}<br v-if="restaurantAddress.companyName">
        {{ restaurantAddress.address || ''}}<br v-if="restaurantAddress.address">
        {{ restaurantAddress.zipCode || '' }} {{ restaurantAddress.city || '' }}<br v-if="restaurantAddress.zipCode || restaurantAddress.city">
        Telefon: {{ restaurantAddress.phoneNumber || '' }}</p>
    </v-col>
      <v-layout align-center justify-center class="pt-5 pb-8" v-else>
        <v-progress-circular
            indeterminate
            size="80"
            color="primary"
        ></v-progress-circular>
      </v-layout>
  </v-row>
</template>

<script>
import {restaurantName} from "@/restaurantConfig";
import {mapGetters} from "vuex";
import {store} from "@/store";

export default {
  name: "contact",
  methods: {
    getImgUrl() {
      return require('../restaurants/' + restaurantName + '/assets/logo.png')
    },
  },
  computed: {
    ...mapGetters({
      restaurantAddress:'restaurantStore/restaurantAddress',
    }),
  },
  created() {
    if(Object.keys(this.restaurantAddress).length == 0) {
      store.dispatch('restaurantStore/fetchRestaurantAddress');
    }
  }
}
</script>

<style scoped>

</style>